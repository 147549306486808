<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        <span v-if="$route.query.month">
          {{
            `${$DATEFORMAT(
              new Date($route.query.year, $route.query.month - 1, 1),
              "LLLL"
            )}
              ${$route.query.year}`
          }}
        </span>
        Pay Cycle
      </h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="w-full mb-4 flex flex-col gap-5 border border-dashed border-romanSilver rounded p-3">
            <div class="w-full flex justify-between items-center">
              <p class="text-darkPurple font-bold text-lg">Trend</p>
              <p @click="isTrend = !isTrend"
                class="cursor-pointer text-blueCrayola font-semibold text-sm"
              >
                  {{isTrend ? `Collapse Trend` : `Expand Trend`}}
              </p>
            </div>
            <div v-if="isTrend" class="w-full flex justify-between gap-1">
              <TrendState
                type="Last month"
                gross-title="Gross salary"
                :gross-salary="totalNetPay"
                emp-title="Employee count"
                :emp-count="totalEmployees"
                icon-name="blue-gross-icon"
              />

              <TrendState
                type="Current month"
                gross-title="Gross salary"
                :gross-salary="totalNetPay"
                emp-title="Employee count"
                :emp-count="totalEmployees"
                icon-name="yellow-gross-icon"
              />

              <TrendState
                type="Variance"
                gross-title="Gross salary"
                :gross-salary="totalNetPay"
                emp-title="Employee count"
                :emp-count="totalEmployees"
                icon-name="green-gross-icon"
              />
            </div>
          </div>
          <div class="p-3" style="border: 1px dashed #878e99">
            <p class="font-bold text-lg mb-4">Pay Run Information</p>
            <div class="flex">
              <card class="p-3 w-52">
                <h6 class="uppercase mb-2 font-bold text-xs text-darkPurple">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.payType }}
                </h6>
              </card>
              <card class="p-3 ml-2">
                <h6 class="uppercase font-bold text-xs text-blueCrayola">
                  Pay Cycle
                </h6>
                <div class="flex">
                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Payment frequency:
                    </h6>
                    <span
                      class="px-2 py-1 ml-8 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{
                        payrunData.payFrequency === "twice_monthly"
                          ? "Twice Monthly"
                          : payrunData.payFrequency === "weekly"
                          ? "Four Times Monthly"
                          : payrunData.payFrequency
                      }}
                    </span>
                  </div>
                  <div class="flex">
                    <h6 class="font-semibold text-sm mt-2 text-jet uppercase">
                      Payment Schedule:
                    </h6>
                    <span
                      class="px-2 py-1 mx-8 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{
                        `${$getOrdinal(payrunData.paySplitPosition)} Payment`
                      }}
                    </span>
                  </div>
                </div>
              </card>
              <card class="w-auto p-2 ml-4">
                <p class="uppercase text-flame mt-2 font-extrabold text-sm">
                  Created at
                </p>
                <p class="text-sm uppercase">
                  {{
                    `${$DATEFORMAT(
                      new Date(payrunData.createdAt),
                      "MMMM dd, yyyy"
                    )}`
                  }}
                </p>
              </card>
            </div>
          </div>
          <div class="flex justify-between my-5">
            <div class="flex flex-1">
              <card class="p-3 ml-2 w-40">
                <h6 class="font-black text-xs text-romanSilver uppercase">
                  Total Employees
                </h6>
                <h4 class="font-bold text-lg text-jet">
                  {{ totalEmployees }}
                </h4>
              </card>
              <card class="p-3 ml-2" style="min-width: 160px">
                <h6 class="font-black text-xs text-romanSilver uppercase">
                  Net Total Pay
                </h6>
                <h4 class="font-bold text-lg text-jet">
                  {{ convertToCurrency(totalNetPay) }}
                </h4>
              </card>
            </div>
            <div class="flex my-auto">
              <Button
                class="text-white buttons flex mr-5"
                :background="appearance.buttonColor || defaultBTNColor"
                @click="save('Under Review')"
                :disabled="disableBtn"
              >
                Submit Pay Run
              </Button>
              <Button
                class="bg-white ml-4 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit;"
                options
                :option-list="['Save as Draft', 'Cancel']"
                @click="buttonOptionSelected($event)"
              >
                Select an Option
              </Button>
            </div>
          </div>
        </card>
      </div>
      <div>
        <div class="px-3"></div>
        <div class="px-3">
          <card class="p-1 mt-6 mb-8">
            <sTable
              :headers="headers"
              :items="tableData"
              style="width: 100%"
              class="w-full"
              :has-checkbox="true"
              aria-label="payRun table"
              :has-number="false"
              :loading="tableLoading"
              @rowSelected="checkboxes = $event"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.photo" class="flex items-center gap-3">
                  <div v-if="isPresent" class="rounded-full sideDot1"></div>
                  <div v-else class="rounded-full sideDot2"></div>
                  <img
                    class=""
                    :src="item.data.photo"
                    v-if="item.data.photo"
                    alt="photo"
                    style="height: 30px; width: 30px; border-radius: 5px"
                  />
                  <div
                    style="height: 35px; width: 35px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2"
                    v-else
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                </div>
                <div v-else-if="item.fname">
                  <span class="tw-font-bold" style="color: #525252"
                    >{{ item.data.fname }} {{ item.data.lname }}</span
                  >
                  <p class="uppercase text-xs text-romanSilver">
                    {{
                      item.data.orgFunction ? item.data.orgFunction.name : ""
                    }}
                  </p>
                </div>
                <span v-else-if="item.regularEarnings" class="tw-text-center">
                  {{
                    !item.data.regularEarnings
                      ? "Nil"
                      : convertToCurrency(item.data.regularEarnings)
                  }}
                </span>
                <span v-else-if="item.additions" class="flex text-center">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.additions"
                  >
                    <template v-slot:title>
                      <div class="underline text-blueCrayola">
                        {{ convertToCurrency(item.data.additions) }}
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(additions, index) in item.data
                          .monthlyAdditionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ additions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(additions.amount) }}
                        </p>
                      </div>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 mt-0 self-center"
                      size="xms"
                      @click.native="handleClick('addition', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.additions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('addition', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
                <span v-else-if="item.deductions" class="flex text-center">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.deductions"
                  >
                    <template v-slot:title>
                      <div class="underline text-blueCrayola">
                        {{ convertToCurrency(item.data.deductions) }}
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(deduction, index) in item.data
                          .monthlyDeductionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ deduction.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(deduction.amount) }}
                        </p>
                      </div>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 self-center"
                      size="xms"
                      @click.native="handleClick('deduction', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.deductions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('deduction', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
                <span v-else-if="item.paye" class="tw-text-center">
                  {{ item.data.paye ? convertToCurrency(item.data.paye) : 0 }}
                </span>
                <span v-else-if="item.employeePension" class="tw-text-center">
                  {{
                    item.data.employeePension
                      ? convertToCurrency(item.data.employeePension)
                      : 0
                  }}
                </span>
                <span v-else-if="item.nhfAmount" class="tw-text-center">
                  {{
                    item.data.nhfAmount
                      ? convertToCurrency(item.data.nhfAmount)
                      : 0
                  }}
                </span>
                <span v-else-if="item.reliefAllowance" class="tw-ml-6">
                  <c-text
                    class="tw--mt-6"
                    style="width: 131px"
                    type="number"
                    placeholder="Enter amount"
                    min="0"
                    @input="
                      checkReimbursement({
                        id: item.data.id,
                        value: item.data.reimbursements,
                      })
                    "
                    v-model="item.data.reimbursements"
                  />
                </span>
                <span v-else-if="item.netPay" class="grosspay">
                  {{ convertToCurrency(item.data.netPay) }}
                </span>
              </template>
            </sTable>
          </card>
        </div>
      </div>
    </div>
    <side-modal
      :open-modal="openModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleClose"
    />
    <edit-side-modal
      :open-modal="openEditModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleEditClose"
    />
    <Modal v-if="cancelModal">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to cancel this Pay run?
          </p>
          <div class="flex">
            <Button
              :background="appearance.buttonColor || defaultBTNColor"
              class="text-white" @click="cancelPayrun"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="cancelModal = false"
            >
              Cancel
            </Button>
        </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import lodash from "lodash/cloneDeep";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
    BackButton,
    Breadcrumb,
    Card,
    CText,
    Menu,
    Button,
    STable,
    SideModal: () => import("./SideModal"),
    EditSideModal: () => import("./EditSideModal"),
    TrendState: () => import("./TrendState"),
  },
  data() {
    return {
      isTrend: false,
      payrunData: {},
      cancelModal: false,
      payment: {},
      processAllEmployees: false,
      disableBtn: false,
      metaData: {},
      itemsPerPage: 10,
      numberOfPage: 1,
      tableLoading: false,
      hierarchy: [],
      openModal: false,
      openEditModal: false,
      modalType: "",
      userId: "",
      isPresent: true,
      payrunType: "",
      categoryArray: [
        {
          name: "Regular",
          value: "Regular",
          radioName: "job",
        },
        {
          name: "Off Cycle",
          value: "Off Cycle",
          radioName: "job",
        },
        {
          name: "Terminal Pay",
          value: "Terminal Pay",
          radioName: "job",
        },
      ],
      additionalItems: [],
      month: [],
      year: [],
      employeeAddition: [],
      amount: "",
      payCycleMonth: "",
      payCycleYear: "",
      reimbursement: "",
      variableMonth: "",
      employeeSearch: false,
      advancedSearch: false,
      netPay: [],
      payType: "",
      payFrequency: "",
      splitPosition: "",
      totalEmployees: 0,
      totalNetPay: 0,
      optimaUsers: [],
      paycycleSummary: [],
      employees: {},
      radioValue: "",
      allButton: "all",
      checkboxes: [],
      selected: [],
      allOptimaUsers: "",
      AVMonth: "",
      loading: true,
      tableData: [],
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Payrun",
          href: "payrun",
          id: "Payrun",
        },
        {
          disabled: false,
          text: `Pay Cycle`,
          id: "Advance Request",
        },
      ],
      item: [],
      setModal: false,
      headers: [
        { title: "", value: "photo" },
        { title: "Employee Name", value: "fname" },
        { title: "Regular Earnings", value: "regularEarnings" },
        { title: "Additions", value: "additions" },
        { title: "Deductions", value: "deductions" },
        { title: "PAYE", value: "paye" },
        { title: "Pension", value: "employeePension" },
        { title: "NHF", value: "nhfAmount" },
        { title: "Net Pay", value: "netPay", width: 10 },
      ],
      page: 1,
      pageCount: 0,
      runByRadioButton: [
        { name: "All", id: "all" },
        { name: "Location", id: "location" },
        { name: "Function", id: "function" },
        { name: "Employment Type", id: "employmentType" },
        { name: "Level", id: "level" },
      ],
      employmentType: [
        { name: "Full-time", id: "Full-time" },
        { name: "Part-time", id: "Part-time" },
        { name: "Intern", id: "Intern" },
        { name: "Company Temp", id: "Company-paid Temp" },
        { name: "Contractor", id: "Independent Contractor" },
        { name: "Agency Temp", id: "Agency-paid Temp" },
        { name: "Vendor Employee", id: "Vendor Employee" },
        { name: "Volunteer", id: "Volunteer" },
      ],
      runByCheckbox: [],

      paymentType: [
        { name: "Regular Pay", id: "regularPay" },
        { name: "13 Month", id: "13thMonth" },
      ],
      selectedPaymentType: "13thMonth",
      drawerVisible: false,
      selitems: [],
      locationArray: [],
      functionArray: [],
      employmentArray: [],
      levelArray: [],
      secondaryCategory: "",
      secondaryCategoryArray: "",
    };
  },
  methods: {
    handleClose() {
      this.getOnePayrun();
      this.openModal = false;
      this.modalType = "";
    },
    handleEditClose() {
      this.getOnePayrun();
      this.openEditModal = false;
      this.modalType = "";
    },
    async handleEdit(type, id) {
      try {
        await this.$handlePrivilege("payRun", "editPayRunOnDraft");
        this.modalType = type;
        this.userId = id;
        this.openEditModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleClick(type, id) {
      try {
        await this.$handlePrivilege("payRun", "editPayRunOnDraft");
        this.modalType = type;
        this.userId = id;
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleProcessAllEmployees() {},
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `?page=${this.numberOfPage}`
        : `?page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOnePayrun(`${pageNumber}${itemPage}`);
    },
    async buttonOptionSelected(option) {
      if (option === "Save as Draft") {
        this.save("Drafts");
      } else {
        this.cancelModal = true
      }
    },
    async cancelPayrun() {
      this.cancelModal = false
      try{
        await this.$_deletePayrun(this.$route.params.id)
        this.$toasted.success(`Pay run cancelled successfully`, {duration:5000})
        this.$router.push({ name: "Pay Runs" });
      }
      catch {
        this.$toasted.error("Pay run cannot be cancelled, please try again", {duration: 5000})
      }
    },
    handleModal() {
      this.setModal = true;
    },
    invalid_permission() {
      this.$store.commit("SET_TOAST", {
        text: "You Do not have the required permission",
        color: "error",
      });
    },

    save(value) {
      this.disableBtn = true;
      if (this.checkboxes.length) {
        const getPayrun = {
          employees: [],
          payrun: {},
          type: "regular"
        };
        getPayrun.payrun.id = this.$route.params.id;

        this.checkboxes.forEach((element) => {
          if (element.reimbursements) {
            parseFloat(element.reimbursements);
          }
          getPayrun.employees.push(element.id);
        });

        if (value === "Drafts") {
          getPayrun.payrun.payrollStatus = "draft";
          getPayrun.payrun.payrunNo = this.payrunData.payrunNo;
        } else {
          getPayrun.payrun.payrollStatus = "review";
        }
        this.$_editOnePayrun(getPayrun)
          .then((result) => {
            this.disableBtn = false;
            this.$toasted.success("Pay run updated successfully", {
              duration: 5000,
            });
            this.$router.push({
              name: "Pay Runs",
              query: { currentTab: value },
            });
            return result;
          })
          .catch((err) => {
            this.disableBtn = false;
            this.$toasted.error(
              "An error occured, Please contact an IT personnel",
              { duration: 5000 }
            );
            return err;
          });
      } else {
        this.$toasted.error(
          "No employee selected, Please select an employee to pay",
          { duration: 3000 }
        );
        this.disableBtn = false;
      }
    },
    checkReimbursement(value) {
      const { id } = value;
      let amount = parseFloat(value.value);
      const hasNetPay = this.netPay.find((element) => element.id === id);
      if (hasNetPay) {
        const data = this.tableData.find((item) => item.id === id);
        if (!amount) amount = 0;
        const initialNetPayObject = lodash(hasNetPay);

        initialNetPayObject.netPay += amount;
        data.netPay = initialNetPayObject.netPay;
      }
    },
    getOnePayrun(params) {
      this.$_getOnePayrun(this.$route.params.id, params).then((result) => {
        const { employees, ...payrun } = result.data.payrun;
        this.payment = result.data.payment;
        this.payrunData = payrun;
        this.metaData = result.data.payrun.meta;

        this.tableData = employees.map((emp) => ({
          ...emp,
          paye: emp.monthlyPAYE,
          status: payrun.payrollStatus,
        }));

        if (employees) {
          employees.forEach((val) => {
            this.netPaySum += val.netPay;
          });
        }
        employees.forEach((item) => {
          this.totalNetPay += item.netPay;
        });
        this.totalEmployees = employees.length;
        this.payCycleMonth = result.data.payrun.month;
        this.payCycleYear = result.data.payrun.year;
      });
      this.loading = false;
      this.tableLoading = false;
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  beforeMount() {
    this.getOnePayrun('?page=1&perPage=50');
  },
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.sideDot1 {
  width: 7px;
  height: 7px;
  background-color: #46aa80;
}
.sideDot2 {
  width: 7px;
  height: 7px;
  background-color: #c3c2c4;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}
</style>
